import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';
import {
  CardSliderWidget as IProps,
} from 'infrastructure/gatsby/types/card-slider';
import Card from '@shipae/components-sandbox/component/card';
import CardTheme from '@shipae/components-sandbox/component/card/theme';
import SmartLink from 'components/smart-link';
import Tabs from '@shipae/components-sandbox/component/tabs';
import TabsThemeNeutral, { TabsThemeType }
  from '@shipae/components-sandbox/component/tabs/theme';
import TabsThemeFreight
  from '@shipae/components-sandbox/component/tabs/theme/shipa-freight';
import TabsThemeDelivery
  from '@shipae/components-sandbox/component/tabs/theme/shipa-delivery';
import TabsThemeEcommerce
  from '@shipae/components-sandbox/component/tabs/theme/shipa-ecommerce';
import TabPanel from '@shipae/components-sandbox/component/tabs/panel';
import Tab from '@shipae/components-sandbox/component/tabs/tab';
import TabThemeNeutral, { TabThemeType }
  from '@shipae/components-sandbox/component/tabs/tab/theme';
import TabThemeFreight
  from '@shipae/components-sandbox/component/tabs/tab/theme/shipa-freight';
import TabThemeDelivery
  from '@shipae/components-sandbox/component/tabs/tab/theme/shipa-delivery';
import TabThemeEcommerce
  from '@shipae/components-sandbox/component/tabs/tab/theme/shipa-ecommerce';
import { fonts, media } from 'src/views/theme';
import { mapColor } from '../index';

const MainContainer = styled.section`
  padding-top: 12rem;
  margin: 0 auto;
  max-width: var(--container-width);

  ${ media.max(767) } {
    display: none;
  }
`;

const Heading = styled.h2`
  margin-bottom: 4.8rem;
  text-align: center;
  ${ fonts.displaym200 }
`;

const TabsEx = styled(Tabs)`
  margin: 0 auto;
`;

type TabThemeListType = {
  tabs: TabsThemeType,
  tab: TabThemeType,
};
type TabsThemesListType = {
  [index: string]: TabThemeListType,
  neutral: TabThemeListType,
  shipaFreight: TabThemeListType,
  shipaDelivery: TabThemeListType,
  shipaEcommerce: TabThemeListType,
};

const TabNeutralEx = {
  ...TabThemeNeutral,
  Main: styled(TabThemeNeutral.Main).attrs({ 'data-ga-trigger': true })``,
};
const TabFreightEx = {
  ...TabThemeFreight,
  Main: styled(TabThemeFreight.Main).attrs({ 'data-ga-trigger': true })``,
};
const TabDeliveryEx = {
  ...TabThemeDelivery,
  Main: styled(TabThemeDelivery.Main).attrs({ 'data-ga-trigger': true })``,
};
const TabEcommerceEx = {
  ...TabThemeEcommerce,
  Main: styled(TabThemeEcommerce.Main).attrs({ 'data-ga-trigger': true })``,
};

const TabsThemes: TabsThemesListType = {
  neutral: {
    tabs: TabsThemeNeutral,
    tab: TabNeutralEx,
  },
  shipaFreight: {
    tabs: TabsThemeFreight,
    tab: TabFreightEx,
  },
  shipaDelivery: {
    tabs: TabsThemeDelivery,
    tab: TabDeliveryEx,
  },
  shipaEcommerce: {
    tabs: TabsThemeEcommerce,
    tab: TabEcommerceEx,
  },
};

const SliderContainer = styled.div`
  display: flex;
  justify-content: center;
  overflow-x: scroll;

  ${ media.max(1290) } {
      justify-content: flex-start;
  }

  ${ media.max(1120) } {
    padding: 0 5rem;
  }

  & > *:not(:last-child) {
    margin-right: calc((100% - (32.8rem * 3)) / 2);

    ${ media.max(1290) } {
      margin-right: 3rem;
    }

    ${ media.md } {
      margin-right: 3rem;
    }
  }
`;

const PanelsContainer = styled.div`
  margin-top: 3.2rem;
  align-self: stretch;
`;

const TabPanelMain = styled.div`
  display: none;

  &.active {
    display: block;
  }
`;

const CardImgItem = styled(CardTheme.CardImgItem)`
  & > *:first-child {
    width: 32.8rem;
    height: 52.8rem;
    border-radius: 1.2rem;

    ${ media.max(1200) } {
      width: 31.6rem;
      height: 51rem;
    }

    ${ media.md } {
      width: 26.1rem;
      height: 42.1rem;
    }
  }
`;

const cardTheme = {
  ...CardTheme,
  CardImgItem,
  CardImgText: styled(CardTheme.CardImgText)
    .attrs({ as: 'h3' })`
      font-weight: 300;
      padding: 3.2rem 2rem;
    `,
  CardImgInfoText: styled(CardTheme.CardImgInfoText)`
    ${ fonts.bodym100 };
    margin-bottom: 1rem;
  `,
};

const CardSlider: React.FC<IProps> = ({
  id,
  sectionId,
  title,
  tabsStyle,
  cardGroups,
}) => {
  const [tabTheme, setTabTheme] = useState(TabsThemes[tabsStyle]?.tab);
  const [tabsTheme, setTabsTheme] = useState(TabsThemes[tabsStyle]?.tabs);

  useEffect(() => {
    setTabTheme(TabsThemes[tabsStyle]?.tab);
    setTabsTheme(TabsThemes[tabsStyle]?.tabs);
  }, [tabsStyle]);

  return (
    <MainContainer id={sectionId} data-ga={`CardSlider-${ id || '#' }`}>
      <Heading>{title}</Heading>
      <TabsEx
        panels={(
          <PanelsContainer>
            {cardGroups.map((group, idx) => (
              <TabPanel
                key={group.id}
                id={`tb${ idx }`}
                theme={{
                  Main: TabPanelMain,
                }}
              >
                <SliderContainer>
                  {group.cards.map((card) => (
                    <div key={card.id}>
                      <Card
                        key={card.id}
                        text={card.title || 'No title'}
                        infoColor={mapColor(card.popup?.backgroundColor)}
                        infoLogoSrc={
                          card.popup?.image?.localFile?.publicURL || ''
                        }
                        infoLogoAlt={
                          card.popup?.image?.alternativeText || ''
                        }
                        infoText={card.popup?.text || 'No text'}
                        infoButtonText={card.link?.label || 'No label'}
                        infoButtonTo={card.link?.url || '#'}
                        linkComponent={SmartLink}
                        theme={cardTheme}
                      >
                        {card.image?.localFile?.childImageSharp?.fixed ? (
                          <GatsbyImage
                            fixed={
                              card.image?.localFile?.childImageSharp?.fixed
                            }
                            alt={card.image?.alternativeText || ''}
                            style={{
                              width: null,
                              height: null,
                            }}
                            loading="lazy"
                            fadeIn={false}
                          />
                        ) : (
                          <img
                            loading="lazy"
                            src={card.image?.localFile?.publicURL}
                            alt={card.image?.alternativeText || ''}
                            style={{
                              width: '328px',
                              height: '528px',
                            }}
                          />
                        )}
                      </Card>
                    </div>
                  ))}
                </SliderContainer>
              </TabPanel>
            ))}
          </PanelsContainer>
        )}
        theme={tabsTheme}
      >
        {cardGroups.map((group, idx) => (
          <Tab
            key={group.id}
            id={`tb${ idx }`}
            active={idx === 0}
            theme={tabTheme}
          >
            {group.title}
          </Tab>
        ))}
      </TabsEx>
    </MainContainer>
  );
};

export default CardSlider;
