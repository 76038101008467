import React from 'react';
import CardSliderDesktop from 'components/card-slider/desktop-card-slider';
import MobileLogisticsAccordion
  from 'components/card-slider/mobile-logistics-accordion';
import {
  CardSliderWidget as IProps,
} from 'infrastructure/gatsby/types/card-slider';
import {
  sd300,
  se300,
  sf300,
  sm300,
} from '@shipae/components-sandbox/component/colors';

export const mapColor = (color: string): string => {
  switch (color) {
    case 'sd300': return sd300();
    case 'sf300': return sf300();
    case 'sm300': return sm300();
    default: return se300();
  }
};

const CardSlider: React.FC<IProps> = ({
  id,
  title,
  tabsStyle,
  cardGroups,
}) => (
  <>
    <CardSliderDesktop
      id={id}
      title={title}
      tabsStyle={tabsStyle}
      cardGroups={cardGroups}
    />
    <MobileLogisticsAccordion
      id={id}
      title={title}
      cardGroups={cardGroups}
    />
  </>
);

export default CardSlider;
