import React from 'react';
import SmartLink from 'components/smart-link';
import LogisticsAccordion
  from '@shipae/components-sandbox/component/logistics-accordion';
import {
  CardSliderWidget as IProps,
} from 'infrastructure/gatsby/types/card-slider';
import styled from 'styled-components';
import { fonts, media } from 'views/theme';
import GatsbyImage from 'gatsby-image/withIEPolyfill';
import { GatsbyImageProps } from 'gatsby-image';
import {
  HeadImageCss,
} from '@shipae/components-sandbox/component/logistics-accordion/theme';
import { mapColor } from '..';

const MainContainer = styled.section`
  display: none;
  padding-top: 12rem;

  ${ media.max(767) } {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    max-width: var(--container-width);
  }
`;

const Heading = styled.div`
  margin-bottom: 4.8rem;
  text-align: center;
  ${ fonts.displaym200 }
`;

const GroupHeading = styled.div`
  padding: 1.6rem 1rem 3.2rem 1rem;
  margin: 0;
  text-transform: uppercase;
  ${ fonts.displayxs400 }
`;

const HeadImage = styled(GatsbyImage)<GatsbyImageProps>`
  ${ HeadImageCss }
`;

type IPropsMob = Omit<IProps, 'tabsStyle'>;

const Accordion: React.FC<IPropsMob> = ({
  id,
  sectionId,
  title,
  cardGroups,
}) => (
  <MainContainer id={sectionId} data-ga={`CardSlider-${ id || '#' }`}>
    <Heading>{title}</Heading>
    {cardGroups.map((group) => (
      <React.Fragment key={group.id}>
        <GroupHeading>{group.title || 'No title'}</GroupHeading>
        <LogisticsAccordion
          entries={group.cards.map((card) => ({
            title: card.title || 'No title',
            imageNode: (card.imageOnMobile.localFile?.childImageSharp?.fluid ? (
              <HeadImage
                fluid={card.imageOnMobile.localFile?.childImageSharp?.fluid}
                alt={card.imageOnMobile.alternativeText || ''}
                objectFit="cover"
                objectPosition="center center"
                loading="lazy"
                fadeIn={false}
              />
            ) : null),
            color: mapColor(card.popup?.backgroundColor),
            logoUrl: card.popup?.image?.localFile?.publicURL || '',
            logoAlt: card.popup?.image?.alternativeText || '',
            text: card.popup?.text || 'No text',
            buttonLabel: card.link?.label || 'No label',
            buttonUrl: card.link?.url || '#',
          }))}
          linkComponent={SmartLink}
        />
      </React.Fragment>
    ))}
  </MainContainer>
);

export default Accordion;
